import React from "react";
import { Link } from "react-router-dom";
import styles from "../assets/styles/course.type.module.css";

const CardComponent = ({ image, title, alt, link, playerType, rank, isDisabled }) => {
  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <div className={styles.card}>
        <img
          alt={alt}
          height="800"
          src={image}
          width="600"
        />
        <div className={styles.cardContent}>
          <h2>{title}</h2>
          {!isDisabled && (
            <p>
              {playerType}
              <span className={styles.rank}>{rank}</span>
            </p>
          )}
        </div>
      </div>
    </Link>
  );
};

export default CardComponent;
