import React from "react";
import "../assets/styles/Home.css";
import Carousel from "../components/Carousel";
import MapComponent from "../components/MapComponent";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import TennisPlayersComponent from "../components/TennisPlayersComponent";
const Home = () => {
  return (
    <div className="container">
      <Helmet>
        <title>Inicio</title>
        <meta
          name="description"
          content="Bienvenido a la mejor academia de tennis en Villavicencio Vanguardia."
        ></meta>
      </Helmet>
      <Carousel />
      <div className="block">
        <div className="text">Academia de Tennis Muñoz</div>
      </div>
      <div className="wrapper">
        <p className="about_title_mobile">Cursos de calidad en Vanguardia</p>
        <div className="about_container">
          <div className="about_image_container">
            <iframe
              className="about_video"
              src="https://www.youtube.com/embed/comzuGbwmwM?si=mZtSvLziKeNrvyxO"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="about_section_text">
            <h2>Hermosas instalaciones en Vanguardia</h2>
            <p>
              Formar parte de la Academia Muñoz durante un curso de formación
              implica integrarse a una comunidad apasionada. La Academia se
              encuentra en la cuidad de Villavicencio vía Vanguardia , la cual
              cuenta con amplias instalaciones y un clima competitivo y
              familiar.
            </p>
            <div className="button_text">
              <Link to={"facilities"} className="btn btn--primary">
                Conoce nuestras instalaciones{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="space-large"></div>
      <TennisPlayersComponent />
      <MapComponent />
    </div>
  );
};

export default Home;
