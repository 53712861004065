import React, { useState, useRef } from "react";
import styles from "../assets/styles/course.type.module.css";
import CardComponent from "./CardComponent";
import useCardsHome from "../hooks/useHomeCards";
import ProgressBar from "./ProgressBar"; 

const TennisPlayersComponent = () => {
  const { cardsHome } = useCardsHome();
  const [progress, setProgress] = useState(0);
  const containerRef = useRef(null); 

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      const maxScroll = scrollWidth - clientWidth;
      const percentage = maxScroll > 0 ? (scrollLeft / maxScroll) * 100 : 0;
      setProgress(percentage);
    }
  };

  const handlePrev = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollBy({ left: -200, behavior: "smooth" }); 
    }
  };

  const handleNext = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollBy({ left: 200, behavior: "smooth" }); 
    }
  };

  return (
    <div className={styles.courses_section}>
      <h2>Cursos</h2>
      <div
        className={styles.container}
        ref={containerRef}
        onScroll={handleScroll}
      >
        {cardsHome.map((card) => (
          <CardComponent
            key={card._id}
            image={card.image}
            title={card.title}
            link={card.link}
            isDisabled={true}
          />
        ))}
      </div>
      <ProgressBar
        progress={progress}
        onPrev={handlePrev}
        onNext={handleNext}
      />
    </div>
  );
  
};

export default TennisPlayersComponent;
