import React from "react";
import styles from "../assets/styles/progressBar.module.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ProgressBar = ({ progress, onPrev, onNext }) => {
  return (
    <div className={styles.container}>
      <div className={styles.progressBar}>
        <div
          className={styles.progress}
          style={{
            background: `linear-gradient(to left, #e0e0e0 ${100 - progress}%, black ${progress}%)`,
          }}
        ></div>
      </div>
      <div className={styles.navButtons}>
        <div className={styles.navButton} onClick={onPrev}>
          <ArrowBackIosIcon style={{ color: "white" }} />
        </div>
        <div className={styles.navButton} onClick={onNext}>
          <ArrowForwardIosIcon style={{ color: "white" }} />
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
